<template>
  <div class="pageGaeRecord">
    <!--主体内容-->
    <div class="gaeRecord">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          ref="searchWrapper"
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item label="父车场名称">
            <el-input v-model="formInline.parkName" placeholder="请输入父车场名称"></el-input>
          </el-form-item>
          <!-- <el-form-item label="同步状态">
            <el-select
              v-model.trim="formInline.syncState"
              style="width: 150px"
              placeholder="请选择同步状态"
            >
              <el-option label="请选择" value=""></el-option>
              <el-option label="待同步" :value="0"></el-option>
              <el-option label="已同步" :value="1"></el-option>
              <el-option label="驳回" :value="2"></el-option>
              <el-option label="归档" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="更新类型">
            <el-select
              v-model.trim="formInline.dataState"
              style="width: 150px"
              placeholder="请选择更新类型"
            >
              <el-option label="请选择" value=""></el-option>
              <el-option label="新增" :value="1"></el-option>
              <el-option label="修改" :value="2"></el-option>
              <el-option label="删除" :value="3"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item :label="$t('searchModule.time_slot')">
            <timeRangePick
              @timeChange="timeChange"
              ref="timeRangePicker"
              :defaultRange="defaultRangeValue"
              :defalutDate="defalutDate"
            />
          </el-form-item> -->
          <!--          v-if="$route.meta.authority.button.query" todo -->
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="searchData"
              v-if="authority.button.query"
              >{{ $t('button.search') }}</el-button
            >
            <el-button type="primary" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="total-wrapper">
        <el-button type="primary" @click="updateData()">更新数据</el-button>
        <div class="time-text">
          <span>上次更新时间</span>
          <span>{{ statisticalData.lastUpdateTime || "xxxx-xx-xx xx:xx:xx" }}</span>
        </div>
        <div class="item-wrapper">
          <div class="text">子车场</div>
          <span class="unit"
            ><span class="bold-wrap">{{ statisticalData.parkCount || 0 }}</span
            >个</span
          >
        </div>
        <div class="item-wrapper">
          <div class="text">已同步子车场</div>
          <span class="unit"
            ><span class="bold-wrap">{{ statisticalData.syncCount || 0 }}</span
            >个</span
          >
        </div>
        <div class="item-wrapper">
          <div class="text">待同步子车场</div>
          <span class="unit"
            ><span class="bold-wrap">{{ statisticalData.unSyncCount || 0 }}</span
            >个</span
          >
        </div>
        <el-button type="primary" @click="$router.push('/synchronizationLog')">同步日志</el-button>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          ref="table"
          header-cell-class-name="header-call-style"
          border
          :data="tableData"
          row-key="parkCode"
          style="width: 100%"
          min-height="400"
          :load="load"
          lazy
          :tree-props="{ children: 'childParkList', hasChildren: 'hasChild' }"
          @expand-change="handleExpandChange"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column header-align="center" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.syncState == 0" @click="controls(1, scope.row)"
                >同步</el-button
              >
              <el-button type="text" @click="controls(0, scope.row)">查看</el-button>
              <!-- <el-button type="text" v-if="scope.row.syncState == 1 && scope.row.slaveRelation != 1" @click="controls(2, scope.row)">归档</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页器-->
      <div class="pagerWrapper">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <syncAlphaParkdialog ref="syncAlphaParkdialog" @searchData="searchData" />
      <syncAlphaArchive ref="syncAlphaArchive" @searchData="searchData" />
    </div>
  </div>
</template>

<script>
// import timeRangePick from "@/components/timePicker";
import syncAlphaParkdialog from "./syncAlphaParkdialog";
import syncAlphaArchive from "./syncAlphaArchive";

export default {
  name: "synchronousCarYard",
  components: {
    // timeRangePick,
    syncAlphaParkdialog,
    syncAlphaArchive,
  },
  data() {
    return {
      // ---------------------------------
      defalutDate: [],
      pageSize: 15,
      page: 1,
      total: 0,
      authority: "",
      statisticalData: [],
      formInline: {
        parkName: "",
        syncState: "",
        dataState: "",
        startTime: "",
        endTime: "",
      },
      tableData: [],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "500",
        },
        {
          prop: "updateTime",
          label: this.$t("list.update_time"),
          width: "",
        },
        {
          prop: "dataState",
          label: "更新类型",
          width: "",
          formatter: (column, row) => {
            switch (column.dataState) {
              case 1:
                return "新增";
              case 2:
                return "修改";
              case 3:
                return "删除";
              default:
                return "";
            }
          },
        },
        {
          prop: "operatorName",
          label: this.$t("list.Processing_personnel"),
          width: "",
        },
        {
          prop: "syncState",
          label: "同步状态",
          width: "",
          formatter: (column, row) => {
            switch (column.syncState) {
              case 0:
                return "待同步";
              case 1:
                return "已同步";
              case 2:
                return "驳回";
              case 3:
                return "归档";
              default:
                return "";
            }
          },
        },
        {
          prop: "handleTime",
          label: this.$t("list.processing_time"),
          width: "",
        },
      ],
    };
  },
  watch: {},
  computed: {
    defaultRangeValue() {
      return [
        {
          typeNumber: this.formInline.plateNumber,
          warntip: "请输入您查询的车牌号/泊位号",
        },
        {
          typeNumber: this.formInline.berthCode,
          warntip: "请输入您查询的车牌号/泊位号",
        },
        {
          typeNumber: this.formInline.berthCode,
          warntip: "请输入您查询的泊位号",
        },
      ];
    },
  },
  methods: {
    handleExpandChange(row, expanded) {
      if (!expanded) {
        let tableRef = this.$refs.table;
        tableRef.store.states.treeData[row.parkCode].loaded = false;
        tableRef.store.states.treeData[row.parkCode].expanded = false;
      }
    },
    // 列表子菜单生成
    load(tree, treeNode, resolve) {
      console.log(tree, treeNode);
      setTimeout(() => {
        this.$axios
          .get("/acb/2.0/syncAlphaPark/getChildPark", {
            data: {
              parkCode: tree.parkCode,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              resolve(res.value);
            }
          })
          .catch((error) => {
            resolve([]);
            // 将倒三角图标重置为未展开状态
            treeNode.loaded = false;
            treeNode.expanded = false;
          });
      }, 1000);
    },

    // 操作
    controls(index, data) {
      if (index == 0) {
        this.$router.push({
          path: "/synchronousCarYardDetail",
          query: {
            parkCode: data.parkCode,
          },
        });
      } else if (index == 1) {
        this.$refs.syncAlphaParkdialog.open(data.parkCode);
      } else {
        this.$refs.syncAlphaArchive.open(data.parkCode);
      }
    },
    // 时间段
    timeChange(timeArr, shortcutKey) {
      console.log("shortcutKey", shortcutKey);
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
      if (shortcutKey) {
        this.searchData();
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 清空
    resetForm() {
      this.$refs["searchWrapper"].clearValidate();
      // this.$refs.timeRangePicker.resetTime();
      this.formInline = {
        parkName: "",
        syncState: "",
        dataState: "",
        startTime: "",
        endTime: "",
      };
      this.searchData();
    },
    // 首页列表查询
    searchData() {
      // 获取统计信息
      this.getTotal();
      this.$axios
        .get("/acb/2.0/syncAlphaPark/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            parkName: this.formInline.parkName,
            syncState: this.formInline.syncState,
            dataState: this.formInline.dataState,
            startDate: this.formInline.startTime,
            endDate: this.formInline.endTime,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.tableData = [];
            this.total = 0;
            this.$alert(res.desc);
          }
        });
    },
    // 获取统计展示数据
    getTotal() {
      this.$axios
        .get("/acb/2.0/syncAlphaPark/statisticsData", {
          data: {
            parkName: this.formInline.parkName,
            syncState: this.formInline.syncState,
            dataState: this.formInline.dataState,
            startDate: this.formInline.startTime,
            endDate: this.formInline.endTime,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.statisticalData = res.value;
          } else {
            this.$alert(res.desc);
          }
        });
    },
    // 更新数据信息
    updateData() {
      this.$axios.get("/acb/2.0/syncAlphaPark/pull").then((res) => {
        if (res.state == 0) {
          this.statisticalData.lastUpdateTime = res.value;
          this.searchData();
        } else {
          this.$alert(res.desc);
        }
      });
    },
  },
  created() {
    this.authority = this.$setAuthority("synchronousCarYard");
    console.log("权限为==>", this.$setAuthority("synchronousCarYard"));
  },
  mounted() {
    this.searchData();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.gaeRecord {
  overflow: hidden;
  background: #fff;



  .tableWrapper {
    .el-table {
      width: 100%!important;
    }
  }
}

.total-wrapper {
  padding: 0 20px;
  margin-bottom: 22px;
  .item-wrapper {
    min-width: 88px;
    margin-bottom: 5px;
    margin-right: 16px;
    padding: 6px 16px;
    display: inline-block;
    background: #F4F8FF;
    border-radius: 4px;
  }

  .time-text {
    width: 153px;
    line-height: 23px;
    display: inline-block;
    padding-left: 20px;
    position: relative;
    top: 11px;
    span {
      display: inline-block;
    }
  }

  .text {
    line-height: 17px;
    color: #425466;
    font-size: 12px;
  }

  .bold-wrap {
    padding-right: 2px;
    position: relative;
    top: 1px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #0f6eff;
    line-height: 25px;
  }

  .unit {
    color: #6C7293;
    font-size: 12px;
  }
}

.labelVerify:before {
  content: '*';
  color: red;
}

.EocConnectDialog {
  .btn {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }


  .titleName {
    font-weight: 800;
    color: #3F9EFF;
    margin-bottom: 10px;
    margin-top: 10px;
    span {
      color: #000;
    }
  }

  .parkInfo {
    height: 115px;
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    padding: 20px;
    .item {
      font-weight: 800;
      width: 25%;
      span {
        display: inline-block;
        font-weight: 500;
      }
    }
  }
}

.gaeRecord /deep/ .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}
</style>
