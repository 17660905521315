<template>
  <div class="gaeRecord">
      <!-- 归档 -->
      <el-dialog title="停车场名称" :visible.sync="dialogTableVisible" width="1164px" class="EocConnectDialog" :close-on-click-modal="false" @close="consoleDetail">
        <div class="parkInfo">
          <div class="item">车场名称：<span>{{ infomation.parkName || '-' }}</span></div>
          <div class="item">车场编码: <span>{{ infomation.parkCode || '-' }}</span></div>
          <div class="item">泊位数：<span>{{  berthList.length || '-' }}</span></div>
          <div class="item">设备品牌：<span>{{ '爱泊车' }}</span></div>
          <div class="item">区域：<span>{{ infomation.areaName || '-' }}</span></div>
          <div class="item">商户名称：<span>{{ infomation.operationName || '-' }}</span></div>
          <div class="item">经纬度：<span>{{ infomation.latitude || '-' }},{{ infomation.longitude || '-' }}</span></div>
          <div class="item">车场设备类型：<span>{{ infomation.parkEquipmentTypeName || '-' }}</span></div>
          <div class="item">关联父车场：<span>{{ infomation.parentParkName || '-' }}</span></div>
        </div>

        <div class="displayFlex dialogHeight">
          <div class="titleName">
            泊位信息：
            <span>共计{{ berthList.length || 0 }}个泊位</span>
            <span class="Instructions">请选择归档泊位，归档后该泊位下线，后续该泊位不支持再上线</span>
          </div>
          <div class="flex dialogLeft">
            <el-table :data="berthList" @selection-change="handleSelectionChange">
              <el-table-column label="归档" align="center" type="selection" width="55"></el-table-column>
              <el-table-column prop="berthCode" label="泊位编码" align="center"></el-table-column>
              <el-table-column prop="barCode" :label="$t('searchModule.Pole_position_number')" align="center"></el-table-column>
              <el-table-column prop="equipmentCode" label="设备编码" align="center"></el-table-column>
              <el-table-column prop="equipmentTypeName" :label="$t('searchModule.Equipment_type')" align="center"></el-table-column>
              <el-table-column prop="parkCarCount" label="有无车辆" align="center" :formatter="parkCarCount"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="btn">
          <el-button type="primary" @click="submit()">归档</el-button>
          <el-button @click="consoleDetail">取消</el-button>
        </div>
      </el-dialog>
  </div>
</template>
<script>
import { request } from '@/common/js/public'
export default {
  components: {
  },
  data() {
    return {
      dialogTableVisible: false,
      berthList: [],
      infomation: [],
      
      rules: {
        areaId: [
          {
            required: true,
            message: "请选择区域",
            trigger: "blur"
          }
        ],
      },
      parkCode: '',
      formInline: {
      }
    }
  },
  computed: {
    // 有无车辆
    parkCarCount() {
      return (val) => {
        switch (val.parkCarCount) {
          case 0:
            return '无车'
          default:
            return '有车'
        }
      };
    }
  },
  methods: {
    // 开启
    open(parkCode) {
      this.parkCode = parkCode;
      this.dialogTableVisible = true;
      this.getsyncAlphaPark(parkCode);
    },
    // 同步弹窗关闭
    consoleDetail() {
      this.formInline.description = '';
      this.dialogTableVisible = false;
    },
    // 归档复选事件
    handleSelectionChange(val) {
      console.log(val)
      this.berthCodes = [];
      val.forEach((value) => {
        this.berthCodes.push(value.berthCode);
      });
    },
    
    // 获取详情信息
    getsyncAlphaPark(parkCode) {
      this.$axios
      .get("/acb/2.0/syncAlphaPark/detail", {
        data: {
          parkCode: parkCode
        },
      })
      .then((res) => {
        if (res.state == 0) {
          this.infomation = res.value;
          this.berthList = res.value.berthList;
        } else {
          this.$alert(res.desc);
        }
      });
    },

    // 提交事件
    submit() {
        const opt = {
          url: `/acb/2.0/syncAlphaPark/archive?parkCode=${this.parkCode}`,
          method: 'post',
          config: {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            },
          },
          data: this.berthCodes,
          success: res => {
            if (res.state == 0) {
              this.consoleDetail();
              this.$emit('searchData')
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
          fail: err => {
            this.$alert(err.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        }
        this.$request(opt)
    }
  },
  mounted() {
  }
}
</script>
<style lang="stylus" scoped>
.labelVerify:before {
  content: '*';
  color: red;
}

.EocConnectDialog {
  .btn {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }

  .ing-icon {
    position: absolute;
    right: 6px;
    top: 3px;

      img {
        width: 100%;
      }
  }

  .titleName {
    font-weight: 800;
    color: #3F9EFF;
    margin-bottom: 10px;
    margin-top: 10px;
    span {
      color: #000;
    }
    .Instructions {
      color: red;
      font-size: 10px;
      padding: 0 20px;
    }
  }

  .parkInfo {
    height: 115px;
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    padding: 20px;
    .item {
      font-weight: 800;
      width: 25%;
      span {
        display: inline-block;
        font-weight: 500;
      }
    }
  }
  .parkInfoA {
    height: 0;
    padding: 20px 0;
  }
}


.gaeRecord /deep/ .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}
</style>
